import React from "react";
import { Link } from "gatsby";

import { Button, H1, H2, Section, SEO, Text } from "components";

const NotFoundPage = () => (
  <>
    <SEO title="404: Page not found" keywords={[`lost`, `404`, `empty`]} />
    <Section pt={3} pb={3} bg="bg.default">
      <div
        css={`
          -ms-grid-column: 1;
          -ms-grid-column-span: 12;
          grid-column: 1 / 13;
          -ms-grid-row: 1;
          grid-row: 1;
          text-align: center;
          ${props => props.theme.mediaQueries.medium} {
            -ms-grid-column: 1;
            -ms-grid-column-span: 8;
            grid-column: 1 / 9;
          }
          ${props => props.theme.mediaQueries.small} {
            -ms-grid-column: 1;
            -ms-grid-column-span: 4;
            grid-column: 1 / 5;
          }
        `}
      >
        <H1 children={`Oops!`} mb="0.25em" color="brand.rose" />
        <H2 children={`404: Page not found`} mb="1em" color="brand.rose" />
        <Text
          children={`We can't seem to find the page you're looking for.`}
          mb="1em"
        />
        <Button children={`Go to homepage`} as={Link} to="/" />
      </div>
    </Section>
  </>
);

export default NotFoundPage;
